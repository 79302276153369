import React, { Component } from 'react';

import styles from './Button.module.css'

export class Button extends Component {
	render() {
		return (
			<span className={styles.Button}>{this.props.children}</span>
		);
	}
}

export default Button