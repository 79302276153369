import React, { Component } from 'react';

import styles from './Footer.module.css'

export class Footer extends Component {
	render() {
		return (
			<div className={styles.Footer}>
				<div className={styles.Address}>
					<p className={styles.small}>
						filzquadrat – Werkstatt für Handfilz und Filzkurse<br />
						Stefanie Hofmann<br />
						Landstrasse 1<br />
						CH 5415 Rieden<br />
						<a href="mailto:stefanie@filzquadrat.ch" aria-label="Stefanie Hofmann eine Email schreiben: stefanie@filzquadrat.ch">stefanie@filzquadrat.ch</a><br />
						<a href="tel:+41786416284" aria-label="Stefanie Hofmann Mobil anrufen 078 641 62 84">+41 78 641 62 84</a><br /><br />
						<a href="/impressum-datenschutz">Impressum & Datenschutz</a>
					</p>
				</div>
			</div>
		);
	}
}

export default Footer