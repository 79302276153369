import React, { Component } from 'react';

import styles from './Loader.module.css';

export class Loader extends Component {
	
	render() {
		return (
			<div className={styles.Loader}>filzquadrat</div>
		);
	}
}

export default Loader