import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import Button from '../Button/Button'

import styles from './Navigation.module.css';

export class Navigation extends Component {
	state = {
		mobileOpened: false
	}

	render() {

		this.clicked = () => {
			let status = this.state.mobileOpened
			this.setState ({
				mobileOpened: !status	
			})
		}

		this.selected = () => {
			this.setState ({
				mobileOpened: false
			})
		}

		let mobileNav

		if (this.state.mobileOpened) {
			mobileNav = (
				<div className={styles.mobileNavOpened}>
					<Link to="/kurse/" aria-label="Zu den Kursen"><p onClick={this.clicked} className={styles.mobileNavElement}><Button>Kurse</Button></p></Link>
					<Link to="/agenda/" aria-label="Zur Agenda"><p onClick={this.clicked} className={styles.mobileNavElement}><Button>Agenda</Button></p></Link>
					<Link to="/ueber-mich/" aria-label="Mehr über mich"><p onClick={this.clicked} className={styles.mobileNavElement}><Button>Über mich</Button></p></Link>
					<Link to="/arbeitsbeispiele/" aria-label="Zu den Arbeitsbeispielen"><p onClick={this.clicked} className={styles.mobileNavElement}><Button>Arbeitsbeispiele</Button></p></Link>
				</div>)
		}
		else {
			mobileNav = "";
		}

		return (
			<div className={styles.Navigation}>
				<Link to="/" aria-label="Zur Startseite"><span className={styles.brand}>filzquadrat</span></Link>
				<Link to="/kurse/" aria-label="Zu den Kursen"><span className={styles.navElement}>Kurse</span></Link>
				<Link to="/agenda/" aria-label="Zur Agenda"><span className={styles.navElement}>Agenda</span></Link>
				<Link to="/ueber-mich/" aria-label="Mehr über mich"><span className={styles.navElement}>Über mich</span></Link>
				<Link to="/arbeitsbeispiele/" aria-label="Zu den Arbeitsbeispielen"><span className={styles.navElement}>Arbeitsbeispiele</span></Link>
				<div onClick={this.clicked} className={[styles.mobileNav,].join(' ')}>
					<span className={styles.navStrokeTop}>.</span>
					<span className={styles.navStrokeMiddle}>.</span>
					<span className={styles.navStrokeBottom}>.</span>
				</div>
				{mobileNav}
			</div>
		);
	}
}

export default Navigation