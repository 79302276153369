import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import './App.css';

// import Home from './Container/Home/Home'
// import Kurse from './Container/Kurse/Kurse'
// import Kursbedingungen from './Container/Kursbedingungen/Kursbedingungen'
// import Erwachsenenkurse from './Container/Kurse/Erwachsenenkurse/Erwachsenenkurse'
// import Kinderkurse from './Container/Kurse/Kinderkurse/Kinderkurse'
// import DetailViewKurs from './Container/Kurse/DetailViewKurs/DetailViewKurs'
// import Ort from './Container/Ort/Ort'
// import OffeneWerkstatt from './Container/OffeneWerkstatt/OffeneWerkstatt'
// import UeberMich from './Container/UeberMich/UeberMich'
// import Agenda from './Container/Agenda/Agenda'
// import DetailViewAgenda from './Container/Agenda/DetailViewAgenda/DetailViewAgenda'
 import Footer from './Components/Footer/Footer'
 import Navigation from './Components/Navigation/Navigation'
 import Loader from './Components/Loader/Loader'
 import ScrollToTop from './Components/ScrollToTop/ScrollToTop'
// import ImpressumDatenschutz from './Container/ImpressumDatenschutz/ImpressumDatenschutz'

const Home = lazy(() => import('./Container/Home/Home'));
const Kurse = lazy(() => import('./Container/Kurse/Kurse'));
const Kursbedingungen = lazy(() => import('./Container/Kursbedingungen/Kursbedingungen'));
const Erwachsenenkurse = lazy(() => import('./Container/Kurse/Erwachsenenkurse/Erwachsenenkurse'));
const Kinderkurse = lazy(() => import('./Container/Kurse/Kinderkurse/Kinderkurse'));
const DetailViewKurs = lazy(() => import('./Container/Kurse/DetailViewKurs/DetailViewKurs'));
const Ort = lazy(() => import('./Container/Ort/Ort'));
const OffeneWerkstatt = lazy(() => import('./Container/OffeneWerkstatt/OffeneWerkstatt'));
const UeberMich = lazy(() => import('./Container/UeberMich/UeberMich'));
const Agenda = lazy(() => import('./Container/Agenda/Agenda'));
const DetailViewAgenda = lazy(() => import('./Container/Agenda/DetailViewAgenda/DetailViewAgenda'));
const Arbeitsbeispiele = lazy(() => import('./Container/Arbeitsbeispiele/Arbeitsbeispiele'));
// const Footer = lazy(() => import('./Components/Footer/Footer'));
// const Navigation = lazy(() => import('./Components/Navigation/Navigation'));
const ImpressumDatenschutz = lazy(() => import('./Container/ImpressumDatenschutz/ImpressumDatenschutz'));


function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <div className="App">
          <Navigation />
          <Suspense fallback={<Loader />}>
            <Routes>
              
              <Route path="/arbeitsbeispiele" element={<Arbeitsbeispiele />} />
              <Route path="/kurse/erwachsenenkurse" exact element={<Erwachsenenkurse />} />
              <Route path="/kurse/kinderkurse" exact element={ <Kinderkurse />} />
              <Route path="/kurse" exact element={ <Kurse />} />
              <Route path="/kurse/:type/:slug" exact element={ <DetailViewKurs />} />
              <Route path="/Kursbedingungen" element={ <Kursbedingungen />} />
              <Route path="/ueber-mich" element={ <UeberMich />} />
              <Route path="/offene-werkstatt" element={ <OffeneWerkstatt />} />
              <Route path="/agenda" exact element={ <Agenda />} />
              <Route path="/agenda/:slug" exact element={ <DetailViewAgenda />} />
              <Route path="/ort/:slug" element={ <Ort />} />
              <Route path="/impressum-datenschutz" element={ <ImpressumDatenschutz />} />
              <Route path="/*" element={ <Home />} />
              
            </Routes>
          </Suspense>
        </div>
        <Footer /> 
        </ScrollToTop> 
      </BrowserRouter>
  );
}

export default App;
